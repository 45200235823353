<template>
    <v-text-field
        flat solo dense hide-details readonly background-color="blue-grey darken-3"
        :prefix="!reverse[format] ? label + ':' : ''" 
        :suffix="reverse[format] ? label + ':' : ''" 
        :value="lookupValue"
        :reverse="reverse[format]"
    >
    </v-text-field>
</template>
<script>
export default {
    name: 'LookupView',
    components: {},
    props: ['label', 'format', 'name', 'value', 'reftable', 'refcol'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'decimal': true, 'euro': true, 'proz': true }
    }),
    computed: {
        lookupValue() {
            if(this.refcol) {
                return this.refcol.name
            } else {
                return ''//'[' + this.refcol + ']'
            }
        } 
    },
    methods: {
    },
}
</script>
<style scoped>
</style>